import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import 'filepond/dist/filepond.min.css'
import React, { useEffect, useState } from 'react'
import { useBlocker } from 'react-router'
import styled from 'styled-components'
import { FilePond, registerPlugin } from 'react-filepond'
import { DataRoomDocument } from 'silta-ai-backend'
import { apiClient } from '../utils/clients'
import { themeVariables } from '../themes/themeVariables'
import { Badge } from './Badge'
import { confirmModal } from './modals/ConfirmationModal'

registerPlugin(FilePondPluginFileValidateSize)

interface DataRoomDocumentUploaderProps {
    dataRoomId: string
    onDocumentAdded?: (document: DataRoomDocument) => void
    onDocumentDeleted?: (document: DataRoomDocument) => void
    onError?: (error: string) => void
    disabled?: boolean
}

const UploaderContainer = styled.div`
    padding: 32px;
    border-radius: 8px;
    box-shadow:
        0px 0px 1px 1px #00000008,
        0px 2px 2px 0px #00000008;

    .filepond--wrapper {
        background-color: ${themeVariables.colors.backgroundContainer};
        border-radius: 8px;
        border: 1px dashed ${themeVariables.colors.disabled};
    }

    .filepond--root {
        margin: 0;
        border-radius: 8px;
        background-color: ${themeVariables.colors.backgroundContainer};
    }

    .filepond--drop-label {
        border-radius: 8px;
        background-color: ${themeVariables.colors.backgroundContainer};
        height: 150px;

        .dropdown-area-label {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .main-text {
                font-weight: ${themeVariables.typography.fontWeight.emphasized};
            }
        }
    }

    .filepond--drip,
    .filepond--panel:not(.filepond--list .filepond--panel) {
        .filepond--panel-center,
        .filepond--panel-bottom {
            background-color: ${themeVariables.colors.backgroundContainer};
        }
    }

    .filepond--label-action {
        text-decoration: none;
        color: ${themeVariables.colors.brand};
    }
`

const SyledBadge = styled(Badge)`
    width: 100%;
    margin-top: 20px;
`

const uploadSvgIconContent = `<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 12.5001V13.5001C18 14.9003 18 15.6003 17.7275 16.1351C17.4878 16.6055 17.1054 16.988 16.635 17.2276C16.1002 17.5001 15.4001 17.5001 14 17.5001H7C5.59987 17.5001 4.8998 17.5001 4.36502 17.2276C3.89462 16.988 3.51217 16.6055 3.27248 16.1351C3 15.6003 3 14.9003 3 13.5001V12.5001M14.6667 6.66679L10.5 2.50012M10.5 2.50012L6.33333 6.66679M10.5 2.50012V12.5001" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`

export default function DataRoomDocumentUploader({
    dataRoomId,
    onDocumentAdded,
    onDocumentDeleted,
    onError,
    disabled = false,
}: DataRoomDocumentUploaderProps) {
    const [errors, setErrors] = useState<string[]>([])
    const [uploadInProgress, setUploadInProgress] = useState(false)
    const blocker = useBlocker(uploadInProgress)

    useEffect(() => {
        if (blocker.state === 'blocked') {
            confirmModal
                .pop({
                    title: 'Upload in progress',
                    content:
                        "Are you sure you want to leave this page? The file upload hasn't completed yet",
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    onResolve: () => {
                        blocker.proceed()
                    },
                    onReject: () => {
                        blocker.reset()
                    },
                })
                .catch(() => {
                    // do nothing
                })
        }
        return () => {
            if (blocker.state === 'blocked') {
                blocker.reset()
            }
        }
    }, [blocker.state])

    return (
        <UploaderContainer>
            <FilePond
                disabled={disabled}
                allowMultiple={true}
                allowRevert={false}
                maxParallelUploads={5}
                maxFileSize="500MB"
                onaddfilestart={() => {
                    setUploadInProgress(true)
                }}
                server={{
                    process: {
                        url: `${apiClient.getBaseURL()}/dataRooms/${dataRoomId}/documents`,
                        headers: apiClient.getRequestHeaders(),
                        onload: (responseBody: string) => {
                            try {
                                const document = JSON.parse(
                                    responseBody
                                ) as DataRoomDocument
                                if (onDocumentAdded) {
                                    onDocumentAdded(document)
                                }
                                setUploadInProgress(false)
                            } catch (err) {
                                console.error(
                                    `onload encountered an error: ${err}`
                                )
                            }

                            // The returned string will be passed to revert if the user hits the undo button
                            return responseBody
                        },
                        onerror: (responseBody: string) => {
                            let error: string
                            try {
                                const errorObject = JSON.parse(responseBody)
                                error = errorObject.error
                            } catch (parseError) {
                                // Wasn't json!
                                error = responseBody
                            }
                            console.log(`onError called with: ${error}`)
                            setErrors((prevErrors) => [...prevErrors, error])
                            setUploadInProgress(false)
                            if (onError) {
                                onError(error)
                            }
                        },
                    },
                    revert: async (stringifiedDocument, load, error) => {
                        const document = JSON.parse(stringifiedDocument)
                        try {
                            setUploadInProgress(true)
                            await apiClient.deleteDataRoomDocument(
                                document.dataRoomId
                            )
                            load()
                            if (onDocumentDeleted) {
                                onDocumentDeleted(document)
                            }
                        } catch (err) {
                            error(`${err}`)
                        } finally {
                            setUploadInProgress(false)
                        }
                    },
                }}
                name="files"
                labelIdle={`<div class="dropdown-area-label">
                                <div>${uploadSvgIconContent}</div>
                                <div class="main-text"><span class="filepond--label-action">Choose files</span> or drag & drop it here</div>
                                <div class="secondary">PDF, DOCx, JPG, JPEG, PNG, BMP, HEIF, TIFF, TIF, XLS, and XLSX format up to 500MB</div>
                            </div>`}
                credits={false}
            />
            {errors.map((error, index) => (
                <SyledBadge key={index} $variant="negative">
                    <span>{error}</span>
                </SyledBadge>
            ))}
        </UploaderContainer>
    )
}
