import { Layout } from 'components/Layout'
import React, { ReactNode, useCallback, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import {
    useIsRunning,
    useRunAssessmentWithFaultyDocumentsHandling,
    useRunningAnswersPollEffect,
} from 'utils/assessments'
import { useRequiredParam } from 'utils/misc'
import { useDeleteAssessment, useUpdateAssessment } from 'utils/mutations'
import {
    useAssessmentQuery,
    useTeamPermissionsForResourceQuery,
} from 'utils/queries'
import { route } from '../utils/routes'
import { AccessDeniedHandler } from './AccessDeniedHandler'
import { AssessmentProperties } from './AssessmentProperties'
import { DotdotdotButton } from './DotdotdotButton'
import { Dropdown, DropdownContent } from './Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { HorizontalLoadingIndicator } from './HorizontalLoadingIndicator'
import { Icon } from './Icon'
import { ListTopBar } from './ListTopBar'
import { confirmModal } from './modals/ConfirmationModal'
import { RunningLabel } from './RunningLabel'
import {
    ShowSidebarProvider,
    Sidebar,
    SidebarHost,
    SidebarSection,
    useShowSidebar,
} from './Sidebar'
import { SidebarToggle } from './SidebarToggle'
import { BreadcrumbsToolbar } from './Toolbar/BreadcrumbsToolbar'
import { useShareResourceModal } from './modals/ShareResourceModal'
import { useNameInputModal } from './modals/NameInputModal'
import { TextLink } from './TextLink'

const LayoutInner = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;

    ${SidebarHost} {
        flex-grow: 1;
    }
`

export function AssessmentPageLayout() {
    const [displaySidebar, setDisplaySidebar] = useState(false)

    const assessmentId = useRequiredParam('id')

    const { t } = useTranslation()

    const assessmentQuery = useAssessmentQuery({ assessmentId })

    const { isFetching, data: assessment } = assessmentQuery

    const deleteAssessment = useDeleteAssessment()

    const { handleRunAssessment, isPending: isPendingRunAssessment } =
        useRunAssessmentWithFaultyDocumentsHandling()

    useRunningAnswersPollEffect(assessment || null)

    const isRunning = useIsRunning(assessment || null)

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            assessmentId,
        })

    const shareResouceModal = useShareResourceModal()
    const triggerShareResource = useCallback(() => {
        if (!assessment) {
            return
        }
        shareResouceModal(assessment.name, { assessmentId: assessment.id }, [
            {
                type: 'Project',
                name: assessment.project.name,
                id: assessment.project.id,
                pointer: {
                    projectId: assessment.project.id,
                },
            },
            ...assessment.precedentDataRooms.map((room) => ({
                type: 'Precedent Databases',
                name: room.name,
                id: room.id,
                pointer: {
                    dataRoomId: room.id,
                },
            })),
        ])
    }, [assessment, shareResouceModal])

    const updateAssessment = useUpdateAssessment()
    const openNameInputModal = useNameInputModal()

    return (
        <AccessDeniedHandler>
            <ShowSidebarProvider value={displaySidebar}>
                <Layout>
                    <LayoutInner>
                        <ListTopBar />
                        <HorizontalLoadingIndicator
                            loading={
                                isFetching ||
                                deleteAssessment.isPending ||
                                teamPermissionIsLoading
                            }
                        />
                        {!!assessment && (
                            <>
                                <BreadcrumbsToolbar
                                    listPage={{
                                        icon: 'pieChartSmall',
                                        name: `All ${t('assessment.plural')}`,
                                        route: route('assessments'),
                                    }}
                                    currentPageName={assessment?.name}
                                    lhsAux={
                                        <>
                                            <Dropdown
                                                trigger={({ onClick }) => (
                                                    <DotdotdotButton
                                                        onClick={onClick}
                                                    />
                                                )}
                                            >
                                                {(dismiss) => (
                                                    <DropdownContent>
                                                        <SelectBasicItem
                                                            disabled={
                                                                isRunning ||
                                                                isPendingRunAssessment ||
                                                                teamPermissionIsLoading ||
                                                                permission ===
                                                                    'Viewer'
                                                            }
                                                            type="button"
                                                            onClick={() => {
                                                                dismiss()

                                                                handleRunAssessment(
                                                                    assessment
                                                                )
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="repeat" />
                                                            </SelectBasicItemIconWrap>
                                                            Re-run{' '}
                                                            {t(
                                                                'assessment.singular'
                                                            )}
                                                        </SelectBasicItem>

                                                        <SelectBasicItem
                                                            type="button"
                                                            disabled={
                                                                teamPermissionIsLoading ||
                                                                permission !==
                                                                    'Owner'
                                                            }
                                                            onClick={() => {
                                                                dismiss()
                                                                triggerShareResource()
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="users" />
                                                            </SelectBasicItemIconWrap>
                                                            Manage permissions
                                                        </SelectBasicItem>

                                                        <SelectBasicItem
                                                            type="button"
                                                            disabled={
                                                                teamPermissionIsLoading ||
                                                                permission ===
                                                                    'Viewer'
                                                            }
                                                            onClick={() => {
                                                                dismiss()
                                                                openNameInputModal(
                                                                    {
                                                                        modalTitle: `Edit ${t('assessment.singular')} name`,
                                                                        buttonCtaText:
                                                                            'Save',
                                                                        buttonCtaSavingText:
                                                                            'Saving',
                                                                        currentName:
                                                                            assessment.name,
                                                                        onConfirm:
                                                                            async (
                                                                                newName: string
                                                                            ) => {
                                                                                await updateAssessment.mutateAsync(
                                                                                    {
                                                                                        id: assessment.id,
                                                                                        name: newName,
                                                                                    }
                                                                                )
                                                                            },
                                                                    }
                                                                )
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="edit" />
                                                            </SelectBasicItemIconWrap>
                                                            Rename
                                                        </SelectBasicItem>

                                                        <SelectBasicItem
                                                            type="button"
                                                            disabled={
                                                                teamPermissionIsLoading ||
                                                                permission !==
                                                                    'Owner'
                                                            }
                                                            onClick={async () => {
                                                                dismiss()

                                                                try {
                                                                    const confirmed =
                                                                        await confirmModal.pop(
                                                                            {
                                                                                title: `Delete ${t('assessment.singular')}`,
                                                                                content: `Are you sure you want to delete the ${t('assessment.singular').toLowerCase()} ${assessment.name}?`,
                                                                                confirmButtonText:
                                                                                    'Delete',
                                                                                cancelButtonText:
                                                                                    'Cancel',
                                                                            }
                                                                        )
                                                                    if (
                                                                        confirmed
                                                                    ) {
                                                                        deleteAssessment.mutate(
                                                                            assessment
                                                                        )
                                                                    }
                                                                } catch (_) {
                                                                    // do nothing
                                                                }
                                                            }}
                                                        >
                                                            <SelectBasicItemIconWrap>
                                                                <Icon name="trash" />
                                                            </SelectBasicItemIconWrap>
                                                            Delete
                                                        </SelectBasicItem>
                                                    </DropdownContent>
                                                )}
                                            </Dropdown>
                                            {isRunning && <RunningLabel />}
                                        </>
                                    }
                                    rhsAux={
                                        <SidebarToggle
                                            value={displaySidebar}
                                            onClick={() => {
                                                setDisplaySidebar((c) => !c)
                                            }}
                                        />
                                    }
                                />
                                <Outlet />
                            </>
                        )}
                        {!isFetching && !assessment && <>Not found</>}
                    </LayoutInner>
                </Layout>
            </ShowSidebarProvider>
        </AccessDeniedHandler>
    )
}

const MainColumn = styled.div`
    flex-grow: 1;
`

interface AssessmentInnerLayoutProps {
    assessmentId: string
    children?: ReactNode
    toolbars?: ReactNode
}

export function AssessmentInnerLayout({
    assessmentId,
    children,
    toolbars,
}: AssessmentInnerLayoutProps) {
    const displaySidebar = useShowSidebar()
    const { t } = useTranslation()

    const { data: assessment = null } = useAssessmentQuery({ assessmentId })

    if (!assessment) {
        return null
    }

    return (
        <>
            {toolbars}
            <SidebarHost>
                <MainColumn>{children}</MainColumn>
                {displaySidebar && (
                    <Sidebar>
                        <SidebarSection title="Properties" emphasized>
                            <AssessmentProperties assessment={assessment} />
                        </SidebarSection>
                        <SidebarSection title={t('project.singular')}>
                            {assessment.project.name}
                        </SidebarSection>
                        <SidebarSection title={t('model.singular')}>
                            {assessment.model?.name || 'N/A'}
                        </SidebarSection>
                        <SidebarSection title={t('dataRoom.plural')}>
                            {assessment.precedentDataRooms.map((room) => (
                                <TextLink
                                    $variant="secondary"
                                    key={room.id}
                                    to={route('dataRoom', room.id)}
                                >
                                    {room.name}
                                </TextLink>
                            ))}
                        </SidebarSection>
                    </Sidebar>
                )}
            </SidebarHost>
        </>
    )
}
